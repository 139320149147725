.noScrollBar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
}

.noScrollBar::-webkit-scrollbar {
    width: 0;
    display: none;
}

.noScrollBar::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.noScrollBar::-webkit-scrollbar-thumb {
    background: #fff;
}

/* Handle on hover */
.noScrollBar::-webkit-scrollbar-thumb:hover {
    background: #fff;
}