.leaflet-container {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.leaflet-popup-content-wrapper {
    background-color: #fff; /* Background color */
    border-radius: 4px; /* Rounded corners */
    padding: 0; /* Inner padding */
    margin: 0;
}

/* Customize popup content */
.leaflet-popup-content {
    color: #333333; /* Text color */
    font-size: 14px; /* Font size */
    line-height: 1; /* Line spacing */
    margin: 0;
    width: fit-content;
}

.leaflet-popup-content p {
    margin: 0;
}

/* Customize popup tip (arrow) */
.leaflet-popup-tip {
    background-color: #ffffff; /* Match the popup background */
}