@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: #fff;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: #d4d4d4;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: #232323;
    --text-primary: #fff;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: #494949;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@property --border-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}

body {
  user-select: none;
  margin: 0;
  line-height: normal;
  overflow-y:auto;
  overflow-x: hidden;
  font-family: 'Noto Sans Arabic', sans-serif;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#root{
  margin: 0;
  padding: 0;
}

.white_filter {
  filter: invert(100%) sepia(0%) saturate(12%) hue-rotate(245deg) brightness(103%) contrast(106%);
}

.primary_filter {
  filter: invert(35%) sepia(52%) saturate(2192%) hue-rotate(345deg) brightness(91%) contrast(96%);
}

.gray_filter{
  filter: invert(72%) sepia(98%) saturate(0%) hue-rotate(153deg) brightness(94%) contrast(105%);
}

.dark_gray_filter{
  filter: invert(51%) sepia(0%) saturate(121%) hue-rotate(139deg) brightness(93%) contrast(85%);
}

.red_filter{
  filter: invert(19%) sepia(94%) saturate(7499%) hue-rotate(359deg) brightness(113%) contrast(116%);
}

.yellow_green_filter{
  filter: invert(79%) sepia(49%) saturate(635%) hue-rotate(27deg) brightness(90%) contrast(89%);
}

.background-blur {
  backdrop-filter: blur(500px);
  -webkit-backdrop-filter: blur(500px);
  background-color: rgba(255, 255, 255, 0.9);
}

::-webkit-scrollbar {
  width: 10px;
  display: block;
}
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c9c9c9;
}
